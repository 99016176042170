import styled from 'styled-components';

export const Line = styled.div`
  background-color: #EDEEF4;
  width: 100%;
  margin: 2.188vw 0;
  height: 1px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1vw;
  position: relative;
  img {
    position: absolute;
    right: 0;
    margin-top: 2px;
    pointer-events: none;
  }
`;

export const SizeMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
  flex: 1;
  select {
    font-family: sofia-pro, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #A7ABBD;
    flex-shrink: 0;
    -webkit-appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    padding-right: 20px;
    @media (max-width: 1500px) {
      font-size: 14px;
    }
  }
`;

export const PriceRangeMenuItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 35.81vw;
  margin-left: 16.38vw;
`;

export const PriceRangeSelect = styled.select`
  font-size: 5.313vw;
  color: #464855;
  -webkit-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  margin-right: ${props => props.margin};
`;

export const MenuLabel = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #A7ABBD;
  flex-shrink: 0;
`;