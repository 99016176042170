import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Heading = styled.div`
  height: 1.063vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.750vw;
  color: #898EA2;
  letter-spacing: 0.3125vw;
  line-height: 5.000vw;
  display: flex;
  justify-content: space-between;
  img {
    margin-left: auto;
    margin-right: 1.250vw;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.000vw;
  color: #A7ABBD;
  letter-spacing: 0.5px;
  line-height: 5.000vw;
  width: 53vw;
  ::placeholder {
    font-family: sofia-pro, sans-serif;
  font-weight: 500;
    font-size: 1.000vw;
    color: #A7ABBD;
    letter-spacing: 0.5px;
    line-height: 5.000vw;
  }
`;

export const Line = styled.div`
  background-color: #EDEEF4;
  margin: 1.375vw 0;
  height: 1px;
`;