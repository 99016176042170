import React from 'react';
import { connect } from 'react-redux';
import { css } from 'styled-components';
import {
  setStreetSize, setTagSize, setBoutiqueFilter,
} from '../../../../../actions/shopActions';
import MultiSelectDropdown from '../../../../MultiSelectDropdown';
import { MenuLabel, SizeMenuItem } from './styled';

function TagSizeMenu({
  label, options = [], setBoutiqueFilter, defaultText = 'Select a size',
}) {
  return (
    <SizeMenuItem>
      <MenuLabel>{label}:</MenuLabel>
      <MultiSelectDropdown inputCss={css`::placeholder {     
        font-family: sofia-pro, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #A7ABBD;  } `} multiple={false} placeHolder={defaultText} plain={true} options={options} update={(value) => setBoutiqueFilter(value)} />
    </SizeMenuItem>
  );
}

const mapDispatchToProps = {
  setStreetSize,
  setTagSize,
  setBoutiqueFilter,
};

export default connect(null, mapDispatchToProps)(TagSizeMenu);
