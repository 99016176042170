import React from 'react';
import SearchBar from './SearchBar/';
import SortMenu from './SortMenu/';
import SideMenu from './SideMenu/';
import ItemsSection from './ItemsSection/';
import Boutiques from './Boutiques/';
import {
  HeaderSection, Body, Container,
} from './styled';

function Shop(props) {
  return (
    <Container>
      <HeaderSection>
        <SearchBar />
        <SortMenu />
      </HeaderSection>
      <Body>
        <SideMenu />
        <ItemsSection {...props} />
      </Body>
      <Boutiques {...props} />
    </Container>
  );
}

export default Shop;
