import React from 'react';
import PlusIcon from './PlusIcon.svg';
import MinusIcon from './MinusIcon.svg';
import {
  Label, LabelContainer, Line,
} from './styled';
import OptionsItem from './OptionsItem';

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }
  render() {
    const {
      label, selectFilter, selectedFilters, shopStyleValues, id,
    } = this.props;
    return (
      <div>
        <LabelContainer onClick={() => this.setState({ active: !this.state.active })}>
          <Label> {label}</Label>
          <img src={this.state.active ? MinusIcon : PlusIcon} />
        </LabelContainer>
        {
          this.state.active && shopStyleValues && shopStyleValues[id] &&
          shopStyleValues[id].map((optionLabel, index) => <OptionsItem key={index} active={selectedFilters.includes(optionLabel)} select={selectFilter} parentLabel={label} label={optionLabel} />)
        }
        <Line />
      </div>
    );
  }
}

export default Item;
