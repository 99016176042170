import React from 'react';
import Section1 from './Section1/';
import Section2 from './Section2/';
import { Container } from './styled';

function SideMenu() {
  return (
    <Container>
      {/* <Section1 /> */}
      <Section2 />
    </Container>
  );
}

export default SideMenu;
