import React from 'react';
import Image from '../../../ImageBasic';
import {
  Container, Info, TopTitle, Line, BottomTitle, Button,
} from './styled';
import BoutiqueImage from './boutique.jpg';

function Boutiques({
  boutique_ad: {
    top_title, bottom_title, button_title,
  },
}) {
  return (
    <Container>
      <Image src={BoutiqueImage} />
      <Info>
        <TopTitle>{top_title}</TopTitle>
        <Line />
        <BottomTitle>{bottom_title}</BottomTitle>
        <Button>{button_title}</Button>
      </Info>
    </Container>
  );
}

export default Boutiques;
