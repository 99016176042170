import React, { Component } from 'react';
import Section1 from './Section1';

export class SortMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }
  render() {
    return (
      <Section1 {...this.props} />
    );
  }
}

export default SortMenu;
