import React from 'react';
import { connect } from 'react-redux';
import { setSortByFilter } from '../../../../../actions/shopActions';
import {
  MenuLabel, SizeMenuItem, OptionsContainer,
} from './styled';
import DownArrowIcon from './DownArrow.svg';

function SortByMenu({
  label, options, index, setSortByFilter, sortByFilter,
}) {
  return (
    <SizeMenuItem key={index}>
      <MenuLabel>{label}:</MenuLabel>
      <OptionsContainer>
        {options && (<select value={sortByFilter} onChange={({ target: { value } }) => setSortByFilter(value)}>
          {<option value="">Select an option</option>}
          {
            options.map(({ label, id }, index) => <option value={id} key={`${label}-${index}`}>{label}</option>)
          }
        </select>)}
        <img src={DownArrowIcon} />
      </OptionsContainer>
    </SizeMenuItem>
  );
}

const mapDispatchToProps = { setSortByFilter };

const mapStateToProps = (state) => ({ ...state.shopReducer });

export default connect(mapStateToProps, mapDispatchToProps)(SortByMenu);
