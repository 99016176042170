import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 9.375vw;
  margin-top: 14.06vw;
  flex-direction: column;
`;

export const Heading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.750vw;
  color: #898EA2;
  letter-spacing: 0.3125vw;
  line-height: 5.000vw;
  display: flex;
  justify-content: space-between;
  margin: 0 8.750vw;
  img {
    margin-left: auto;
    margin-right: 3.438vw;
  }
`;

export const Input = styled.input`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.750vw;
  color: #898EA2;
  letter-spacing: 0.3125vw;
  line-height: 5.000vw;
  width: 53vw;
  ::placeholder {
    font-family: sofia-pro, sans-serif;
  font-weight: 500;
    font-size: 3.750vw;
    color: #898EA2;
    letter-spacing: 0.3125vw;
    line-height: 5.000vw;
  }
`;

export const Separator = styled.div`
  margin-left: 3.438vw;
`;

export const Line = styled.div`
  background-color: #EDEEF4;
  margin-top: 9.375vw;
  margin-left: 9.375vw;
  margin-right: 12.50vw;
  height: 1px;
`;