import * as types from './actionTypes';

export function setSortByFilter(sortByFilter) {
  return {
    type: types.SET_SORT_BY_FILTER,
    sortByFilter,
  };
}

export function setStyleFilter(styleFilterKey, styleFilterValue) {
  return {
    type: types.SET_STYLE_FILTER,
    styleFilterKey,
    styleFilterValue,
  };
}

export function setPriceRange(priceRangeUpperBound, priceRangeLowerBound) {
  return {
    type: types.SET_PRICE_RANGE,
    priceRangeUpperBound: priceRangeUpperBound.toString().replace(/[^0-9]/ig, ''),
    priceRangeLowerBound: priceRangeLowerBound.toString().replace(/[^0-9]/ig, ''),
  };
}

export function setTagSize(tagSizeFilter) {
  return {
    type: types.SET_TAG_SIZE_FILTER,
    tagSizeFilter,
  };
}

export function setBoutiqueFilter(boutiqueFilter) {
  return {
    type: types.SET_BOUTIQUE_FILTER,
    boutiqueFilter,
  };
}

export function setStreetSize(streetSizeFilter) {
  return {
    type: types.SET_STREET_SIZE_FILTER,
    streetSizeFilter,
  };
}

export function setSearchValue(shopSearchValue) {
  return {
    type: types.SET_SERACH_FILTER,
    shopSearchValue,
  };
}