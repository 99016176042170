import styled from 'styled-components';

export const MenuItemContainer = styled.div`
  margin-left: 8.750vw;
`;

export const Line = styled.div`
  background-color: #EDEEF4;
  width: 100%;
  margin: 2.188vw 0;
  height: 1px;
`;

export const Container = styled.div`
  margin-top: 19.06vw;
  ${MenuItemContainer}:last-child {
    ${Line} {
      margin-bottom: 0;
    }
  }
`;

export const OptionsContainer = styled.div`
  position: relative;
  span {
    font-family: sofia-pro, sans-serif;
    font-weight: 500;
    font-size: 4.375vw;
    color: #A7ABBD;
    line-height: 7.500vw;
  }
  img {
    position: absolute;
    right: 0;
    pointer-events: none;
  }
`;

export const SizeMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%);
  }
  select {
    font-size: 5.313vw;
    color: #464855;
    line-height: 7.500vw;
    -webkit-appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    padding-right: 20px;
  }
`;

export const PriceRangeMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PriceRangeSelect = styled.select`
  font-size: 5.313vw;
  color: #464855;
  line-height: 7.500vw;
  -webkit-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  margin-right: ${props => props.zero ? '-30px' : props.margin};
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%);
  }
  select {
    font-size: 5.313vw;
    color: #464855;
    line-height: 7.500vw;
    -webkit-appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    padding-right: 20px;
  }
`;

export const MenuLabel = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 4.375vw;
  color: #A7ABBD;
  line-height: 7.500vw;
`;