import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: white;
  padding: 6.250vw 9.813vw;
  img {
    height: 32.50vw;
    width: 48.38vw;
    object-fit: contain !important;
  }
`;

export const Info = styled.div`
  background-color: #E4DAD9;
  height: 32.50vw;
  width: 28.88vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 3.125vw;
`;

export const TopTitle = styled.div`
  margin-top:5.375vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: #464855;
  letter-spacing: 0.06250vw;
  text-align: center;
`;
export const BottomTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.875vw;
  color: #464855;
  letter-spacing: 0.1250vw;
  line-height: 3.250vw;
  text-align: center;
  margin: 0 30px;
`;
export const Button = styled.div`
  justify-content: center;
  align-items:center;
  display: flex;
  border-radius: 0.6250vw;
  background-color: #CAA09C;
  height: 3.000vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.7500vw;
  color: #FFFFFF;
  letter-spacing: 0.86px;
  line-height: 1.250vw;
  margin: 3.125vw 6.938vw;
`;
export const Line = styled.div`
  margin: 0 auto;
  height: 0.06250vw;
  width: 5.000vw;
  background-color: #5D5D5D;
`;