import styled from 'styled-components';

export const Container = styled.div`
    height: 17.81vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      height: 6.250vw;
      width: 6.250vw;
      margin-right: 3.125vw;
      transition: opacity .2s ease-in;
      opacity: ${props => props.active ? 0 : 1};
    }
    cursor: pointer;
`;

export const Line = styled.div`
    border-bottom: 1px #464855 solid;
    transform: scaleY(0.4);
`;

export const Search = styled.input`
    font-size: 5.625vw;
    color: #464855;
    letter-spacing: 0.3125vw;
    width: ${props => props.active ? '80vw' : '50vw'};
    transition: width .2s ease-in .1s;
`;
export const SearchContainer = styled.div`
  position: absolute;
  display: flex;
  transition: left .2s ease-in;
  left: ${props => props.active ? '0' : '33%'};
  justify-content: center;
  display: flex;
  align-items: center;
`;