import React from 'react';
import { connect } from 'react-redux';
import { setStyleFilter } from '../../../../../actions/shopActions';
import {
  ResetFiltersLabel, Container,
  SelectedFiltersContainer, SelectedFiltersLabel, SelectedFiltersLabelContainer,
} from './styled';
import Item from './Item';
import RemoveIcon from './RemoveIcon.svg';

const labels = [
  {
    id: 'designer',
    label: 'Designer',
  },
  {
    id: 'material',
    label: 'Material',
  },
  {
    id: 'neckline',
    label: 'Neckline',
  },
  {
    id: 'sleeve',
    label: 'Sleeve',
  },
  {
    id: 'silhouette',
    label: 'Silhouette',
  },
];

class Section3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedFilters: [] };
    this.select = this.select.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  select(key, label) {
    if (!label) {
      Object.keys(this.props.shopStyleValues).forEach((type) => {
        if (this.props.shopStyleValues[type].includes(key)) {
          label = type;
        }
      });
    }

    const copy = [...this.state.selectedFilters];

    if (copy.includes(key)) {
      copy.splice(copy.indexOf(key), 1);
    } else {
      copy.push(key);
    }
    this.setState({ selectedFilters: copy });
    this.props.setStyleFilter(label, key);
  }
  resetAll() {
    this.setState({ selectedFilters: [] });
    this.props.setStyleFilter();
  }
  render() {
    return (
      <Container>
        {
          labels.map((data, index) => <Item key={index} selectedFilters={this.state.selectedFilters} selectFilter={this.select} {...data} shopStyleValues={this.props.shopStyleValues} />)
        }
        <SelectedFiltersContainer>
          <div>Filter By</div>
          {
            this.state.selectedFilters.map((label, index) => (
              <SelectedFiltersLabelContainer key={index}>
                <img onClick={() => this.select(label, null)} src={RemoveIcon} />
                <SelectedFiltersLabel>{label}</SelectedFiltersLabel>
              </SelectedFiltersLabelContainer>
            ))
          }
          {!!this.state.selectedFilters.length &&
            <ResetFiltersLabel onClick={this.resetAll}>
              Reset All
            </ResetFiltersLabel>}
        </SelectedFiltersContainer>
      </Container>
    );
  }
}
const mapDispatchToProps = { setStyleFilter };

const mapStateToProps = (state) => ({ ...state.shopReducer });

export default connect(mapStateToProps, mapDispatchToProps)(Section3);
