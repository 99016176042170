import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 2.938vw;
  padding: 0 1.875vw;
  padding-top: 1.875vw;
`;

export const Heading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.7500vw;
  color: #898EA2;
  letter-spacing: 0.06250vw;
  line-height: 1.000vw;
  display: flex;
  justify-content: space-between;
  img {
    margin-left: auto;
  }
`;

export const Input = styled.input`
  width: 9.438vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.7500vw;
  color: #898EA2;
  letter-spacing: 0.06250vw;
  line-height: 1.000vw;
  ::placeholder {
    font-family: sofia-pro, sans-serif;
  font-weight: 500;
    font-size: 0.7500vw;
    color: #898EA2;
    letter-spacing: 0.06250vw;
    line-height: 1.000vw;
  }
`;

export const Line = styled.div`
  background-color: #EDEEF4;
  height: 1px;
`;