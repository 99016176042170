import React from 'react';
import { connect } from 'react-redux';
import {
  setStreetSize, setTagSize, setBoutiqueFilter,
} from '../../../../../actions/shopActions';
import {
  MenuLabel, SizeMenuItem, OptionsContainer,
} from './styled';
import DownArrowIcon from './DownArrow.svg';

function TagSizeMenu({
  label, options = [], id, setStreetSize, setTagSize, setBoutiqueFilter,
  defaultText = 'Select a size',
}) {
  return (
    <SizeMenuItem>
      <MenuLabel>{label}:</MenuLabel>
      <OptionsContainer>
        <select onChange={({ target: { value } }) => id === 'streetSize' ? setStreetSize(value) : id === 'boutique' ? setBoutiqueFilter(value) : setTagSize(value)}>
          {<option value="">{defaultText}</option>}
          {
            options.map((option_key, index) => <option key={index}>{option_key}</option>)
          }
        </select>
        <img src={DownArrowIcon} />
      </OptionsContainer>
    </SizeMenuItem>
  );
}

const mapDispatchToProps = {
  setStreetSize,
  setTagSize,
  setBoutiqueFilter,
};

export default connect(null, mapDispatchToProps)(TagSizeMenu);
