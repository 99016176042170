import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import qs from 'qs';
//containers
import ShopDesktop from '../components/Desktop/Shop';
import ShopMobile from '../components/Mobile/Shop';
//components
import Layout from '../components/Layout';
//actions
import { setMetaTags } from '../actions/homeActions';
import './style.css';
import { formatShopData } from '../helpers/Wordpress';
//helpers

function Shop({
  data, setMetaTags, ...props
}){
  let querySearch;
  const dataFormatted = { ...formatShopData(data) };

  if (typeof(window) !== 'undefined') {
    const queryObject = qs.parse(window.location.search.replace('?', ''));
    querySearch = queryObject.search;
  }

  useEffect(() => {
    const description = 'SoBridely Shop Your Favorite Style';
    const image = 'https://firebasestorage.googleapis.com/v0/b/sobridely-dev.appspot.com/o/Screen%20Shot%202020-05-27%20at%203.45.25%20AM.png?alt=media&token=19f7ea72-6c7f-4da8-9910-480a421af477';
    const url = window.location.href;
    const title = `SoBridely Shop`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);

  return (
    <Layout
      mobile={<ShopMobile querySearch={querySearch} {...dataFormatted} {...props} />}
      desktop={<ShopDesktop querySearch={querySearch} {...dataFormatted} {...props} />}
    />
  );
}

const mapStateToProps = (state) => ({
  ...state.shopReducer,
  ...state.userReducer,
});

const mapDispatchToProps = { setMetaTags };

export const query = graphql`
query {
  shop_data: allWordpressPage {
    edges {
      node {
        acf {
          boutique_ad {
            mobile_image {
              source_url
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                  fixed(quality: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            top_title
            bottom_title
            button_title
          }
        }
      }
    }
  }
}
`;

export default connect(mapStateToProps, mapDispatchToProps)(Shop);