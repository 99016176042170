import React from 'react';
import { connect } from 'react-redux';
import { setSearchValue } from '../../../../../actions/shopActions';
import {
  Container, Heading, Input, Separator, Line,
} from './styled';
import SearchIcon from './SearchIcon.svg';
const LABEL = 'STYLE NAME / NUMBER...';
let input_ref;

function Section2({ setSearchValue }) {
  return (
    <Container onClick={() => input_ref.focus()}>
      <Heading>
        <Input onChange={({ target: { value } }) => setSearchValue(value)} ref={(ref) => input_ref = ref} placeholder={LABEL} />
        <Separator>|</Separator>
        <img src={SearchIcon} />
      </Heading>
      <Line />
    </Container>
  );
}

const mapDispatchToProps = { setSearchValue };

export default connect(null, mapDispatchToProps)(Section2);
