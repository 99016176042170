import React, { useState } from 'react';
import {
  Container, Header, DropdownContainer,
} from './styled';
import DownArrowIcon from './DownArrow.svg';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';

function SortMenu() {
  const [active, setActive] = useState(false);
  return (
    <Container>
      <Header onClick={() => setActive(!active)}
        active={active}>
        <div>Sort by</div>
        <img src={DownArrowIcon} />
      </Header>
      <DropdownContainer active={active}>
        <Section1 /> {/* Top section before style / number search */}
        <Section2 /> {/* Style / number search field */}
        <Section3 /> {/* Collapsable section Designer/material/neckline etc */}
      </DropdownContainer>
    </Container>
  );
}

export default SortMenu;
