import React from 'react';
import {
  SelectOption, Checkbox, SelectOptionLabel, InnerCheckBox, CheckboxContainer,
} from './styled';

class OptionsItem extends React.Component {
  select(key, parentLabel) {
    this.props.select(key, parentLabel);
  }
  render() {
    const {
      label, active, parentLabel,
    } = this.props;
    return (
      <SelectOption onClick={() => this.select(label, parentLabel)}>
        <CheckboxContainer>
          <Checkbox />
          {active && <InnerCheckBox />}
        </CheckboxContainer>
        <SelectOptionLabel>{label}</SelectOptionLabel>
      </SelectOption>
    );
  }
}

export default OptionsItem;
