import React from 'react';
import { connect } from 'react-redux';
import { setSortByFilter } from '../../../../../actions/shopActions';
import {
  MenuItemContainer, MenuLabel, Line, MenuItem, OptionsContainer,
} from './styled';
import DownArrowIcon from './DownArrow.svg';

function SortByMenu({
  label, options, setSortByFilter, sortByFilter,
}) {
  return (
    <MenuItemContainer>
      <MenuItem>
        <MenuLabel>{label}:</MenuLabel>
        <OptionsContainer>
          {options && (<select value={sortByFilter} onChange={({ target: { value } }) => setSortByFilter(value)}>
            {<option value="">Select an option</option>}
            {
              options.map(({ label, id }, index) => <option value={id} key={`${label}-${index}`}>{label}</option>)
            }
          </select>)}
          <img src={DownArrowIcon} />
        </OptionsContainer>
      </MenuItem>
      <Line />
    </MenuItemContainer>
  );
}

const mapDispatchToProps = { setSortByFilter };

const mapStateToProps = (state) => ({ ...state.shopReducer });

export default connect(mapStateToProps, mapDispatchToProps)(SortByMenu);
