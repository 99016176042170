/* global $ */
import React from 'react';
import { connect } from 'react-redux';
import { setPriceRange } from '../../../../../../actions/shopActions';
import { SLIDER_MAX } from '../../../../../../constants';
import { formatAmount } from '../../../../../../helpers/Format';

import {
  MenuLabel, PriceRangeMenuItem, SliderContainer, SliderBackground, SliderBound, SliderColorValue, SliderValue,
} from './styled';
var throttle = require('lodash.throttle');
var debounce = require('lodash.debounce');

class PriceRangeMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderLowerBound: 0,
      sliderUpperBound: SLIDER_MAX,
      sliderUpperPosition: null,
      sliderLowerPosition: null,
      mouseDownBound: null,
    };
    this.updateLowerBound = this.updateLowerBound.bind(this);
    this.updateUpperBound = this.updateUpperBound.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  componentDidMount() {
    this.setState({
      sliderLowerPosition: 0,
      sliderUpperPosition: $('#price-slider').width(),
    });
    $('body').on('mouseup', () => {
      this.setState({ mouseDownBound: false });
      $('body').unbind('mousemove');
    });
    $('#price-slider-lower').on('mousedown', (e) => this.handleMouseDown(e, 'lower'));
    $('#price-slider-upper').on('mousedown', (e) => this.handleMouseDown(e, 'upper'));
    $(window).on('resize', () => {
      debounce(() => {
        this.setState({
          sliderLowerBound: 0,
          sliderUpperBound: SLIDER_MAX,
          sliderUpperPosition:$('#price-slider').width(),
          sliderLowerPosition: 0,
        });
      }, 400, { trailing: true, leading: false })();
    });
  }

  componentWillUnmount() {
    $(window).off('resize');
  }
  handleDrag(x, bound) {
    if (bound === 'upper') {
      this.updateUpperBound(x);
    } else {
      this.updateLowerBound(x);
    }
  }
  handleMouseDown(e, bound) {
    const _this = this;
    this.setState({ mouseDownBound: bound });
    $('body').on('mousemove', (e) => {
      if (_this.state.mouseDownBound === bound) {
        const relativeDragX = e.pageX - $('#price-slider').offset().left;
        this.handleDrag(relativeDragX - 5, bound);
      }
    });
  }
  updateLowerBound(sliderLowerPosition) {
    if (sliderLowerPosition >= 0) {
      const dollarValue = (sliderLowerPosition / $('#price-slider').width()) * SLIDER_MAX;
      let sliderLowerBound = Math.ceil(Math.round(dollarValue) / 100) * 100;

      if (sliderLowerBound < 100) {
        sliderLowerBound = 0;
        sliderLowerPosition = 0;
      }

      if (sliderLowerBound > SLIDER_MAX - 100) {
        sliderLowerPosition = $('#price-slider').width();
        sliderLowerBound = SLIDER_MAX;
      }
      this.setState({
        sliderLowerPosition,
        sliderLowerBound,
      });
    } else {
      this.setState({
        sliderLowerPosition: 0,
        sliderLowerBound: 0,
      });
    }
  }
  updateUpperBound(sliderUpperPosition) {
    if (sliderUpperPosition <= $('#price-slider').width()) {
      const dollarValue = (sliderUpperPosition / $('#price-slider').width()) * SLIDER_MAX;
      let sliderUpperBound = Math.ceil(Math.round(dollarValue) / 100) * 100;

      if (sliderUpperBound > SLIDER_MAX - 100) {
        sliderUpperPosition = $('#price-slider').width();
        sliderUpperBound = SLIDER_MAX;
      }

      if (sliderUpperBound < 100) {
        sliderUpperPosition = 0;
        sliderUpperBound = 0;
      }
      this.setState({
        sliderUpperPosition,
        sliderUpperBound,
      });
    } else {
      this.setState({
        sliderUpperPosition: $('#price-slider').width(),
        sliderUpperBound: SLIDER_MAX,
      });
    }
  }
  render() {
    const { label } = this.props;
    const { sliderLowerBound, sliderUpperBound } = this.state;
    const throttled = throttle(() => {
      this.props.setPriceRange(sliderUpperBound, sliderLowerBound);
    }, 1000);
    throttled();
    return (
      <PriceRangeMenuItem>
        <MenuLabel>{label}:</MenuLabel>
        <SliderContainer id="price-slider">
          <SliderBackground></SliderBackground>
          <SliderBound value={this.state.sliderLowerPosition} id="price-slider-lower" />
          <SliderColorValue left={this.state.sliderLowerPosition} width={this.state.sliderUpperPosition - this.state.sliderLowerPosition}></SliderColorValue>
          <SliderBound value={this.state.sliderUpperPosition} id="price-slider-upper" />
        </SliderContainer>
        <SliderValue>${formatAmount(this.state.sliderLowerBound * 100)} to ${formatAmount(this.state.sliderUpperBound * 100)}</SliderValue>
      </PriceRangeMenuItem>
    );
  }
}

const mapDispatchToProps = { setPriceRange };

export default connect(null, mapDispatchToProps)(PriceRangeMenu);
