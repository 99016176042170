import React from 'react';
import { connect } from 'react-redux';
import * as utils from '../../../../../helpers/Format';
import { setPriceRange } from '../../../../../actions/shopActions';
import { SLIDER_MAX } from '../../../../../constants';
import {
  MenuItemContainer, MenuLabel, Line, PriceRangeMenuItem, OptionsContainer, PriceRangeSelect,
} from './styled';
var throttle = require('lodash.throttle');

class PriceRangeMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option_1: '$0',
      option_2: `${SLIDER_MAX}`,
    };
  }
  render() {
    const {
      label, options, setPriceRange,
    } = this.props;
    const throttled = throttle(() => {
      setPriceRange(this.state.option_2, this.state.option_1);
    }, 1000);
    throttled();
    return (
      <MenuItemContainer>
        <PriceRangeMenuItem>
          <MenuLabel>{label}:</MenuLabel>
          <OptionsContainer>
            <div style={{
              marginRight: '10vw',
              width: '50vw',
            }}>
              <PriceRangeSelect zero={this.state.option_1 == '$0'} onChange={({ target: { value: option_1 } }) => this.setState({ option_1 })}
                margin={`${(-(this.state.option_1.length * 10) + (this.state.option_1.length - 2) * 15)}%`}>
                {
                  options[0].map((option_key) => <option>${utils.formatAmount(option_key, 0)}</option>)
                }
              </PriceRangeSelect>
              <span>{` TO `}</span>
              <PriceRangeSelect onChange={({ target: { value: option_2 } }) => this.setState({ option_2 })}
                margin={`${(-(this.state.option_2.length * 10) + (this.state.option_2.length - 2) * 15)}%`}>

                {
                  options[1].map((option_key) => <option>${utils.formatAmount(option_key, 0)}</option>)
                }
              </PriceRangeSelect>
            </div>
          </OptionsContainer>
        </PriceRangeMenuItem>
        <Line />
      </MenuItemContainer>
    );
  }
}

const mapDispatchToProps = { setPriceRange };

export default connect(null, mapDispatchToProps)(PriceRangeMenu);
