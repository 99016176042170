import styled from 'styled-components';

export const HeaderSection = styled.div`
  max-height: 11.56vw;
  background-color: white;
  padding: 3.125vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Body = styled.div`
  margin: 2.438vw 0;
  display: flex;
`;

export const Container = styled.div`
  background-color: #F4F5FA;
`;