import React, { useState } from 'react';
import Sifter from 'sifter';
import Item from '../../../Item';
import {
  Container, ItemsContainer, LoadMoreButton,
} from './styled';

function ShopItems(props) {
  const [amountToShow, setAmountToShow] = useState(0);

  function applyStyleFilters(item) {
    const {
      priceRangeLowerBound, priceRangeUpperBound, tagSizeFilter, streetSizeFilter, styleKeyFilters,
      boutiqueFilter,
    } = props;
    let valid = true;

    if (item.salePrice / 100 <= priceRangeLowerBound || item.salePrice / 100 >= priceRangeUpperBound) {
      //filter on price
      valid = valid && false;
    }

    if (boutiqueFilter) {
      if (item.boutique !== boutiqueFilter ) {
        //filter on tag size
        valid = valid && false;
      }
    }

    if (tagSizeFilter) {
      if (item.tagSize != tagSizeFilter) {
        //filter on tag size
        valid = valid && false;
      }
    }

    if (streetSizeFilter) {
      if (item.streetSize != streetSizeFilter) {
        //filter on street size
        valid = valid && false;
      }
    }

    if (Object.keys(styleKeyFilters)) {
      Object.keys(styleKeyFilters).forEach((label) => {
        let labelMatches = false;

        if (styleKeyFilters[label].includes(item[label.toLowerCase()])) {
          labelMatches = true;
        }
        valid = labelMatches && valid;
      });
    }
    return valid;
  }

  function applySearch(data) {
    const { shopSearchValue, querySearch } = props;
    const search = shopSearchValue || querySearch;

    if (search === 'recent') {
      return data.sort((a, b) => a.date - b.date);
    }

    if (search === 'boutique') {
      return data.filter(({ boutique }) => !!boutique);
    }

    if (!search) {
      return data;
    }

    if (!data.length) {
      return data;
    }

    var sifter = new Sifter(data);
    const options = {
      respect_word_boundaries: true,
      filter: true,
      fields: Object.keys(data[0]).filter((key) => !['photos'].includes(key)),
    };
    const { items } = sifter.search(search, options);
    const res = items.map(({ id }) => ({ ...data[id] }));
    return res;
  }

  function applySorting(itemsToShow) {
    const { sortByFilter } = props;

    if (sortByFilter) {
      switch (sortByFilter) {
        default:
        case 'sale': {
          return itemsToShow.sort((a, b) => (a.price - a.salePrice) - (b.price - b.salePrice));
        }
        case 'high-low': {
          return itemsToShow.sort((a, b) => b.salePrice - a.salePrice);
        }
        case 'low-high': {
          return itemsToShow.sort((a, b) => a.salePrice - b.salePrice);
        }
        case 'popular': {
          return itemsToShow.sort((a, b) => a.views.length - b.views.length);
        }
        case 'date': {
          return itemsToShow.sort((a, b) => b.date - a.date);
        }
      }
    } else {
      return itemsToShow;
    }
  }

  const { products } = props;
  let itemsToShow = [];

  Object.keys(products).find((id) => {
    itemsToShow.push(products[id]);
  });
  itemsToShow = itemsToShow.filter(applyStyleFilters);
  itemsToShow = applySearch(itemsToShow);
  itemsToShow = applySorting(itemsToShow);
  return (
    <Container>
      <ItemsContainer>
        {
          itemsToShow.map((data, index) =>
            <Item key={index} {...data} />,
          )
        }
      </ItemsContainer>
      {amountToShow < itemsToShow.length && <LoadMoreButton onClick={() => setAmountToShow(amountToShow + 6 )}>Load More Dresses...</LoadMoreButton>}
    </Container>
  );
}

export default ShopItems;
