import styled from 'styled-components';

export const DropdownContainer = styled.div`
  transform: ${props => props.active ? 'translate(-50%, 0%) scaleY(1)' : 'translate(-50%, -50%) scaleY(0)'};
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100vw;
  background: #FFFFFF;
  box-shadow: 0 10px 14px 0 rgba(69,73,91,0.08);
  transition: transform .1s ease-in-out;
`;

export const Container = styled.div`
  position: relative;
  z-index: 2;
`;

export const Header = styled.div`
  display: flex;
  font-size: 5.625vw;
  color: #464855;
  letter-spacing: 0.3125vw;
  justify-content: center;
  align-items: center;
  height: 17.50vw;
  box-shadow: 0 3.125vw 4.375vw 0 rgba(69,73,91,0.08);
  & > div {
    margin-right: 3.750vw;
  }
  img {
    transform: ${props => props.active ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;