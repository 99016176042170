import React from 'react';
import {
  Container, Heading, Input, Line,
} from './styled';
import SearchIcon from './SearchIcon.svg';
const LABEL = 'STYLE NAME / NUMBER...';
let input_ref;

function Section1() {
  return (
    <Container onClick={() => input_ref.focus()}>
      <Heading>
        <Input ref={(ref) => input_ref = ref } placeholder={LABEL} />
        <img src={SearchIcon} />
      </Heading>
      <Line />
    </Container>
  );
}

export default Section1;
