import React from 'react';
import { connect } from 'react-redux';
import { setSearchValue } from '../../../../actions/shopActions';
import {
  Container, Heading, Input, Line,
} from './styled';
import SearchIcon from './SearchIcon.svg';
const LABEL = 'Search for something specific';
let input_ref;

function Section2({ setSearchValue }) {
  return (
    <Container onClick={() => input_ref.focus()}>
      <Heading>
        <Input onChange={({ target: { value } }) => setSearchValue(value)} ref={(ref) => input_ref = ref} placeholder={LABEL} />
        <img src={SearchIcon} />
      </Heading>
      <Line />
    </Container>
  );
}

const mapDispatchToProps = { setSearchValue };

export default connect(null, mapDispatchToProps)(Section2);
