import React from 'react';
import { connect } from 'react-redux';
import { setSearchValue } from '../../../../actions/shopActions';
import {
  Container, Line, Search, SearchContainer,
} from './styled';
import MagnifyingGlass from './MagnifyingGlass.svg';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputActive: false,
      input: '',
    };
  }
  render() {
    const { setSearchValue } = this.props;
    return (
      <div>
        <Container active={this.state.inputActive || this.state.input}
          onBlur={() => this.setState({ inputActive: false })}
          onFocus={() => this.setState({ inputActive: true })}>
          <SearchContainer active={this.state.inputActive || this.state.input}>
            <img src={MagnifyingGlass} />
            <Search active={this.state.inputActive || this.state.input} onChange={({ target: { value: input } }) => {
              this.setState({ input });
              setSearchValue(input);
            }} value={this.state.input} placeholder="Search"></Search>
          </SearchContainer>
        </Container>
        <Line />
      </div>
    );
  }
}

const mapDispatchToProps = { setSearchValue };

export default connect(null, mapDispatchToProps)(SearchBar);
