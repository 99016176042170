import styled from 'styled-components';

export const Container = styled.div`
  background-color: #F4F5FA;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  align-items: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 9.375vw 4.688vw 0;
  background: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(69,73,91,0.08);
`;

export const ItemHeader = styled.div`
  height: 16.25vw;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const New = styled.div`
  width: 18.75vw;
  background-color: #7174A8;
  height: 100%;
  align-self: flex-start;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.125vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;
export const ItemTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 5.625vw;
  color: #464855;
  text-align: center;
  margin: 0 auto;
`;
export const ItemPicture = styled.div`
height: 68.80vw;
background-color: #EDE7E3;
display: flex;
justify-content: center;
  img {
    object-fit: contain;
    width: 56.88vw;
    margin: 0 auto;
  }
`;
export const ItemDetailsContainer = styled.div`
    background-color: white;
    height: 43.13vw;
    margin: 4.375vw 6.563vw;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const ItemPrice = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.750vw;
  color: #9196A8;
  text-decoration: line-through;
`;
export const ItemSalePrice = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 5.625vw;
  color: #464855;
`;
export const ItemSavePercentage = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.750vw;
  color: #6B969D;
`;
export const ItemListNumber = styled.div`
  margin-top: 2.188vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 4.375vw;
  color: #898EA2;
  line-height: 7.500vw;
`;
export const ItemCondition = styled.div`
  text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 4.375vw;
  color: #898EA2;
  line-height: 7.500vw;`;
export const ItemFooter = styled.div`
  height: 15.63vw;
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 0 5.313vw;
  background-color: #FBFCFE;
  box-shadow: 0 -0.3125vw 0 0 #EDEEF4; 
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 4.375vw;
  color: #FE95A2;
`;

export const LoadMoreButton = styled.div`
  background: #FE95A2;
  font-size: 4.063vw;
  color: white;
  letter-spacing: 0.3125vw;
  line-height: 5.625vw;
  border-radius: 3.125vw;
  margin: 20.31vw 4.375vw;
  height: 14.37vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;