import styled from 'styled-components';

export const MenuLabel = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: #A7ABBD;
  line-height: 1.500vw;
  white-space: nowrap;
`;
export const PriceRangeMenuItem = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex: 1;
`;
export const SliderContainer = styled.div`
  z-index: 0;
  width: 21.88vw;
  height: 1.125vw;
  position: relative;
  margin: 0 1.125vw;
  align-items: center;
  display: flex;
  @media (max-width: 1500px) {
    width: 15vw;
  }
`;
export const SliderBackground = styled.div`
  height: 0.6250vw;
  background-color: #EDEEF4;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;
export const SliderBound = styled.div`
  cursor: pointer;
  background-color: white;
  border: #E4E5EC 1px solid;
  height: 1.125vw;
  width: 0.6250vw;
  z-index: 2;
  position: absolute;
  transition: all 0.1s ease;
  left: ${props => props.value + 'px'};
`;
export const SliderColorValue = styled.div`
  background-color: #FE95A2;
  height: 0.6250vw;
  transition: all 0.1s ease;
  width: ${props => props.width + 'px'};
  margin-left: ${props => props.left + 'px'};
  z-index: 1;
`;
export const SliderValue = styled.div`
  font-size: 0.8750vw;
  color: #6B6F81;
  line-height: 1.125vw;
  margin-top: .2em;
  flex-shrink: 0;
  font-family: sofia-pro, sans-serif;
`;