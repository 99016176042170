import React, { useEffect, useState } from 'react';
import { formatNumberInput } from '../../helpers/Format/numbers.js';
import Image from '../ImageBasic';
import { getUUID } from '../../helpers/Utils/index.js';
import {
  Container, Label, Select, ChildContainer,
} from './styled.js';
import IconSVG from './dropdown_icon.svg';

function ThemedDropdown({
  label: _label = '', type: _type, update, value,
  required, pattern, labelStyle, max, plain,
  children, disabled, options, multiple = true, placeHolder,
  inputCss, ...other
}) {
  const [label, setLabel] = useState(_label || getUUID().toString());

  useEffect(() => {
    $('.flexdatalist').flexdatalist({ minLength: 0, multiple });
  }, [multiple]);
  useEffect(() => {
    $('.flexdatalist').on('change:flexdatalist', function (e) {
      update(e.target.value);
    });
  }, [update]);

  let type = _type;

  if (_type === 'amount' || _type === 'number') {
    type = 'tel';
  }

  if (_type === 'amount' && value) {
    value = formatNumberInput(value);
  }

  if (max && value) {
    value = value.substr(0, max);
  }

  return (
    <Container {...other}>
      {_label && <Label shrink={label.length > 10} style={labelStyle}>{label}</Label>}
      {!children ? (
        <>
          <Select css={inputCss} placeholder={placeHolder} plain={plain} value={value} className="flexdatalist" list={label} disabled={disabled} pattern={pattern} type={type} required={required}/>
          <datalist id={label}>
            {options.map((key) => <option key={key}>{key}</option>)}
          </datalist>
        </>
      ) :
        <ChildContainer disabled={disabled}>
          {children}
        </ChildContainer>
      }
      {!plain && <Image style={{
        position: 'absolute', top:'calc(50% + 16px)', right: 15,
        transform: 'translate(-50%, -50%)',
      }} src={IconSVG} />}
    </Container>
  );
}

export default ThemedDropdown;