import React from 'react';
import SearchBar from './SearchBar/';
import SortMenu from './SortMenu/';
import ShopItems from './ShopItems/';

function Shop({ ...props }) {
  return (
    <div>
      <SearchBar />
      <SortMenu />
      <ShopItems
        {...props} />
    </div>
  );
}

export default Shop;
