import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 1.875vw;
  padding-top: 1.313vw;
  padding-bottom: 5.813vw;
`;

export const SelectedFiltersLabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0.2500vw 0;
  cursor: pointer;
`;

export const SelectedFiltersContainer = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: #6B6F81;
  letter-spacing: 0.5px;
`;

export const ResetFiltersLabel = styled.div`
  font-size: 0.7500vw;
  color: #FF2E49;
  letter-spacing: 0.5px;
`;

export const SelectedFiltersLabel = styled.div`
  font-size: 0.7500vw;
  color: #6B6F81;
  letter-spacing: 0.5px;
  line-height: 1.375vw;
  margin-left: 0.6250vw;
  text-transform: capitalize;
`;

export const Label = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: #6B6F81;
  letter-spacing: 0.5px;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const Line = styled.div`
  background-color: #EDEEF4;
  height: 1px;
  margin-top: 1.375vw;
  margin-bottom: 1.688vw;
`;

export const SelectOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.8750vw 0;
`;

export const SelectOptionLabel = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 0.7500vw;
  color: #6B6F81;
  letter-spacing: 0.5px;
  margin-left: 0.6250vw;
  text-transform: capitalize;
  cursor: pointer;
`;

export const Checkbox = styled.div`
  width: 1.000vw;
  height: 1.000vw;
  border: 1px solid #E4E5EC;
`;

export const InnerCheckBox = styled.div`
  background-color: #FE95A2;
  width: 0.5000vw;
  height: 0.5000vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CheckboxContainer = styled.div`
  position: relative;
    cursor: pointer;
`;